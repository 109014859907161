import { Flex, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IMenu, HomeMenus } from '../menus'

interface IProps {
  curPath: string
}

function HomeHeaderMenus(props: IProps) {
  const { curPath } = props
  const navigate = useNavigate()

  return (
    <>
      {HomeMenus.map((item: IMenu, index: number) => {
        return (
          <Flex
            key={`${item.path}${index}`}
            ml="32px"
            mr="32px"
            onClick={() => {
              if (item.linkType === 'link') {
                window.open(item.path)
              } else if (item.linkType === 'router') {
                navigate(item.path)
              } else if (item.linkType === 'scroll') {
                document
                  .getElementById(item.path)
                  ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
              }
            }}
            color={item.linkType !== 'none' && curPath === item?.path ? 'blue.100' : 'white.1'}
            cursor="pointer"
            _hover={{
              color: 'blue.800',
            }}
          >
            {item.icon && <Image w="36px" h="fit-content" src={item.icon} />}
            <Text ml="7px" fontSize="16">
              {item.name}
            </Text>
          </Flex>
        )
      })}
    </>
  )
}

export default HomeHeaderMenus
