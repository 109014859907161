export interface IMenu {
  name: string
  path: string
  linkType: 'none' | 'router' | 'link' | 'scroll'
  icon?: string
}

export const HomeMenus: IMenu[] = [
  {
    name: 'Home',
    path: 'home',
    linkType: 'scroll',
  },
  {
    name: 'Earn',
    path: 'earn',
    linkType: 'scroll',
  },
  {
    name: 'Roadmap',
    path: 'roadmap',
    linkType: 'scroll',
  },
  {
    name: 'Whitepaper',
    path: 'https://ai-bull.gitbook.io/aibull-1',
    linkType: 'link',
  },
]
