import { Box } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import Header from '../Header'
import BG2 from '@/assets/background2.png'

interface IProps {
  children: ReactNode
  [key: string]: any
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const { children, ...others } = props
  return (
    <Box minH={'100vh'} pos={'relative'} zIndex={99} {...others}>
      <Header />
      <Box minH={'80vh'}>
        <Box
          bgImage={BG2}
          bgRepeat={'no-repeat'}
          bgPosition={'center top'}
          bgSize={'100%'}
          w={'100%'}
          pb={'48.95%'}
          top={0}
          zIndex={-3}
          position={'absolute'}
        ></Box>
        {children}
      </Box>
    </Box>
  )
}

export default Layout
