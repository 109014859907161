import { useState, useEffect, useMemo } from 'react'
import { Flex, Link, Image, Box } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import HomeHeaderMenus from './components/HomeHeaderMenus'
import CustomConnectButton from '../CustomConnectButton'
import BG2 from '@/assets/background2.png'
import AIBullIcon from '@/assets/ai-bull-icon.png'

const Header = () => {
  const location = useLocation()
  const [curPath, setPath] = useState('/')

  useEffect(() => {
    setPath(location?.pathname || '/')
  }, [location])

  const customHeader = useMemo(() => {
    switch (curPath) {
      case '/':
        return <HomeHeaderMenus curPath={curPath} />
    }
    return <></>
  }, [curPath])

  return (
    <Box
      position={'sticky'}
      h={'80px'}
      top={0}
      overflow={'hidden'}
      bg={'transparent'}
      transition={'all 0.3s ease 0s'}
      zIndex={99}
    >
      <Box
        pos={'absolute'}
        bgImage={BG2}
        bgRepeat={'no-repeat'}
        bgPosition={'center top'}
        bgSize={'100%'}
        w={'100%'}
        pb={'48.95%'}
        top={0}
        zIndex={-3}
      ></Box>
      <Flex
        px={{ base: '4px', md: '40px' }}
        alignItems="center"
        justifyContent={'space-between'}
        h={'80px'}
      >
        <Link href="/">
          <Image src={AIBullIcon} alt="logo" />
        </Link>
        <Flex display={{ base: 'none', md: 'flex' }}>{customHeader}</Flex>
        <CustomConnectButton />
      </Flex>
    </Box>
  )
}

export default Header
