import customRequest from '@/utils/request'
import { userHeroNftByTokenIdListPath } from './constance'
import { Chains, Network } from '@/contracts/constants'
import { INftItem } from './interface'

/**
 * 根据ID list获取nft信息
 * @param tokenIdList nft id列表
 * @returns nft信息列表
 */
export async function userHeroNftByTokenIdList(
  tokenIdList: number[],
  nftAddressList: string[]
): Promise<INftItem[]> {
  return await customRequest(userHeroNftByTokenIdListPath, 'post', {
    tokenIdList,
    nftAddressList,
    chainId: Chains[Network].chainId,
  })
}
