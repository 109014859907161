import { Global } from '@emotion/react'

const Fonts = () => {
  return (
    <Global
      styles={`
        /* Proto Mono */
        @font-face {
          font-family: 'Proto Mono';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('./fonts/ProtoMono-Bold.otf');
        }
        /* Proto Mono */
        @font-face {
          font-family: 'Proto Mono';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('./fonts/ProtoMono-Bold.otf');
        }
        /* Roboto Mono */
        @font-face {
          font-family: 'Roboto Mono';
          font-display: swap;
          src: url('./fonts/ProtoMono-Regular.otf');
        }`}
    />
  )
}

export default Fonts
