export const Network = 'Conflux eSpace (Testnet)'

export const Chains: any = {
  ['Conflux eSpace (Testnet)']: {
    chainId: 71,
    CRT: '0xbc90ba776865660aAb390449277c8ECA77c0f2b6',
    FC: '0xa930c831e276C79B62Bc33c92bBefE181890B0a4',
    CFX: '',
    CrazyHeroNFTFactory: '0x96bd30136D352972b0c2333429BaC1A05D40d672',
    CrazyHeroBlindBoxPre: '0xc45D714b42d998fD465d31b86ba536d77e3C10e0',
    CrazyHeroNFT: '0xd37e5a667fe9b208851a8ebe77582a7f6c9d8916',
    CrazyMarket: '0x564FaB0907d4252Fe06434f72237fc1CB4b76793',
    GrazyHeroNFTG1: '0x09E35bC83842b523cb63b4ed510d01eD3816Cc87',
  },
}

export const eSpaceTestnet = {
  id: 71,
  name: 'Conflux eSpace (Testnet)',
  network: 'Testnet',
  nativeCurrency: {
    name: 'Conflux eSpace (Testnet)',
    symbol: 'CFX',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://evmtestnet.confluxrpc.com'],
    },
    public: {
      http: ['https://evmtestnet.confluxrpc.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Conflux eSpace (Testnet)',
      url: 'https://evmtestnet.confluxscan.net',
    },
  },
}

export const currentNetwork = eSpaceTestnet
