import { currentNetwork } from '@/contracts/constants'
import { PublicClient } from 'viem'
import { create } from 'zustand'

type IState = {
  curNet: string
  curChainId: number
  userAddress: string
  userData: {
    allowanceFC: bigint
    allowanceCFX: bigint
    allowanceCRT: bigint
    accountBoxMap: {
      count: number
      boxIndex: bigint
    }
    userFCTotal: bigint
    userCFXTotal: bigint
    userCRTTotal: bigint
    userNfts: {
      nftIdList: bigint[]
      size: bigint
    }
  }
  publicClient?: PublicClient
}

const globalStore = create<IState>(() => ({
  curNet: currentNetwork.name,
  curChainId: currentNetwork.id,
  userAddress: '',
  userData: {
    allowanceFC: BigInt(0),
    allowanceCFX: BigInt(0),
    allowanceCRT: BigInt(0),
    accountBoxMap: {
      count: 0,
      boxIndex: BigInt(0),
    },
    userFCTotal: BigInt(0),
    userCFXTotal: BigInt(0),
    userCRTTotal: BigInt(0),
    userNfts: {
      nftIdList: [],
      size: BigInt(0),
    },
  },
  marketCartList: [],
  currentDetailItem: {
    id: 0,
    status: 'front',
    grade: 0,
    force: 1000,
    name: 'Name Name',
    nature: 'fire',
    rarity: 'green',
    address: '',
  },
}))

export default globalStore
