// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react'

import px2vw from '@/utils/px2vw'
import styles from './styles'

// 2. Extend the theme to include custom colors, fonts, etc

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1200px',
  xl: '1440px',
}

const colors = {
  bg: '#F1F2E6',
  button: {
    1: 'linear-gradient(270deg, rgb(51, 212, 250) 0%, rgb(23, 243, 221) 100%)',
  },
  font: {
    1: 'rgb(14, 19, 33)',
    2: 'rgb(103, 115, 149)',
  },
  white: {
    1: 'rgba(255, 255, 255, 1)',
  },
  red: {
    100: '#F30D0D',
  },
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
  gray: {
    100: '#9292A4',
    200: '#404152',
    300: '#292939',
  },
  yellow: {
    100: '#FFD055',
    200: '#BCBCBC',
    300: '#FDD770',
    400: '#F45800',
  },
  blue: {
    1: 'rgb(23, 243, 221)',
    2: 'rgb(30, 39, 64)',
    100: '#5FC1FF',
    200: '#0C7FE3',
    300: '#252637',
    400: '#060818',
    500: '#60C1FF',
    600: '#8D97A8',
    700: '#3AFFFF',
  },
  brown: {
    100: '#845612',
  },
}
const textStyles = {
  '12': {
    fontSize: {
      base: px2vw(12),
      md: '12px',
    },
    lineHeight: {
      base: px2vw(12),
      md: '12px',
    },
  },
  '14': {
    fontSize: {
      base: px2vw(14),
      md: '14px',
    },
    lineHeight: {
      base: px2vw(14),
      md: '14px',
    },
  },
  '16': {
    fontSize: {
      base: px2vw(16),
      md: '16px',
    },
    lineHeight: {
      base: px2vw(16),
      md: '16px',
    },
  },
  '18': {
    fontSize: {
      base: px2vw(18),
      md: '18px',
    },
    lineHeight: {
      base: px2vw(18),
      md: '18px',
    },
  },
  '20': {
    fontSize: {
      base: px2vw(20),
      md: '20px',
    },
    lineHeight: {
      base: px2vw(20),
      md: '20px',
    },
  },
  '22': {
    fontSize: {
      base: px2vw(22),
      md: '22px',
    },
    lineHeight: {
      base: px2vw(22),
      md: '22px',
    },
  },
  '24': {
    fontSize: {
      base: px2vw(24),
      md: '24px',
    },
    lineHeight: {
      base: px2vw(24),
      md: '24px',
    },
  },
  '30': {
    fontSize: {
      base: px2vw(30),
      md: '30px',
    },
    lineHeight: {
      base: px2vw(30),
      md: '30px',
    },
  },
  '36': {
    fontSize: {
      base: px2vw(36),
      md: '36px',
    },
    lineHeight: {
      base: px2vw(36),
      md: '36px',
    },
  },
}

const radii = {
  none: '0',
  sm: '5px',
  md: '10px',
  sxl: '12px',
  xl: '16px',
  lg: '18px',
  llg: '20px',
  lllg: '32px',
  round: '50%',
  select: '15px',
  input: '4px',
}

export const theme = extendTheme({
  colors,
  breakpoints,
  textStyles,
  radii,
  fonts: {
    body: '"Proto Mono","Helvetica","Arial","sans-serif"',
  },
  sizes: {
    xl: '1080px',
  },
  styles,
})
