import { Button, Flex } from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'

const CustomConnectButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated')
        return (
          <Flex>
            {(() => {
              if (!connected) {
                return (
                  <Button
                    bg={'button.1'}
                    color={'font.1'}
                    _hover={{
                      bg: 'button.1',
                      opacity: 0.8,
                    }}
                    onClick={openConnectModal}
                  >
                    Connect Wallet
                  </Button>
                )
              }
              if (chain.unsupported) {
                return (
                  <Button
                    bg={'button.1'}
                    color={'font.1'}
                    _hover={{
                      bg: 'button.1',
                      opacity: 0.8,
                    }}
                    w={'100%'}
                    onClick={openChainModal}
                  >
                    Wrong network
                  </Button>
                )
              }
              return (
                <>
                  <Button
                    bg={'button.1'}
                    color={'font.1'}
                    _hover={{
                      bg: 'button.1',
                      opacity: 0.8,
                    }}
                    w={'100%'}
                    onClick={openAccountModal}
                  >
                    {account.displayName}
                  </Button>
                </>
              )
            })()}
          </Flex>
        )
      }}
    </ConnectButton.Custom>
  )
}

export default CustomConnectButton
