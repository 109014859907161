import React from 'react'
import '@rainbow-me/rainbowkit/styles.css'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
// import { goerli, mainnet } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { currentNetwork } from '@/contracts/constants'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    currentNetwork,
    // goerli,
    // mainnet,
  ],
  [publicProvider()]
)

const { connectors } = getDefaultWallets({
  appName: 'Crazy Road',
  projectId: 'Crazy_Road',
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
})

export const PanelContext = React.createContext({})

interface IProps {
  children: React.ReactNode
}

function RainbowkitProvider({ children }: IProps) {
  const contextValue = {}
  return (
    <PanelContext.Provider value={contextValue}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>{children}</RainbowKitProvider>
      </WagmiConfig>
    </PanelContext.Provider>
  )
}

export default RainbowkitProvider
