import { ChakraProvider } from '@chakra-ui/react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import RainbowkitProvider from '@/components/Layout/rainbowkit-provider'
import UserProvider from '@/components/Layout/user-provider'
import { theme } from '@/theme'
import Layout from '@/components/Layout'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Fonts from '@/theme/fonts'
import Home from './pages/Home'
const queryClient = new QueryClient()

function App() {
  return (
    <RainbowkitProvider>
      <ChakraProvider theme={theme}>
        <Fonts />
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <BrowserRouter>
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<>Not To Found</>} />
                </Routes>
              </Layout>
            </BrowserRouter>
          </UserProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </RainbowkitProvider>
  )
}

export default App
