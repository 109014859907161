import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  Image,
  SimpleGrid,
  CardHeader,
  Card,
  CardBody,
  Heading,
  Link,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Footer from '@/components/Footer'
import Map from '@/components/Map'
import PhotoImage from '@/assets/photo.jpg'
import IconTop from '@/assets/icon.png'
import Icon1 from '@/assets/icon1.png'
import Icon2 from '@/assets/icon2.png'
import Icon3 from '@/assets/icon3.png'
import Icon4 from '@/assets/icon4.png'
import One from '@/assets/one.png'
import Two from '@/assets/two.png'
import Three from '@/assets/three.png'
import BinanceIcon from '@/assets/binance.svg'
import LogoOkx from '@/assets/logo_okx.png'
import LogoTp from '@/assets/logo_tp.png'
import LogoMM from '@/assets/logo_mm.png'
import LogoPs from '@/assets/logo_ps.png'
import LogoZks from '@/assets/logo_zks.png'
import LogoGalxe from '@/assets/logo_galxe.png'

const Home: React.FC = () => {
  const [mintValue, setMintValue] = useState('0')
  const [userMax, setUserMax] = useState(20)
  useEffect(() => {
    setUserMax(20)
  }, [])
  return (
    <>
      {/* 1 */}
      <Box maxW={'1200px'} m={'auto'} pt={'120px'} id="home">
        <Text fontSize={'xl'} textAlign={'center'} color={'blue.1'}>
          The Ultimate Journey Launch on zkSync
        </Text>
        <Text fontSize={'5xl'} textAlign={'center'} fontWeight={'600'} lineHeight={1.1} mt={'24px'}>
          Propelling you into the thrilling AI world like never before
        </Text>
      </Box>
      {/* 2 */}
      <Box mt={{ base: '50px', md: '100px' }} mb={{ base: '50px', md: '320px' }}>
        <Box
          m={'auto'}
          pos={'relative'}
          maxW={'98%'}
          w={{ base: '100%', md: '720px' }}
          p={'64px 8px 60px'}
          borderRadius={'16px'}
          _before={{
            content: '""',
            zIndex: -2,
            pos: 'absolute',
            w: 'calc(100% + 2px)',
            h: 'calc(100% + 2px)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            bgImage:
              'linear-gradient(270deg, rgb(156, 81, 255) 0%, rgb(85, 161, 255) 50%, rgb(156, 81, 255) 100%)',
          }}
          _after={{
            content: '""',
            zIndex: -2,
            pos: 'absolute',
            w: 'calc(100% - 2px)',
            h: 'calc(100% - 2px)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            bg: 'rgb(6, 8, 24)',
          }}
        >
          <Flex direction={'column'} alignItems={'center'}>
            <Text
              fontSize={'4xl'}
              align={'center'}
              bg={
                'linear-gradient(270deg, rgb(156, 81, 255) 0%, rgb(85, 161, 255) 50%, rgb(156, 81, 255) 100%) text'
              }
              color={'transparent'}
            >
              AIBull Presale!
            </Text>
            <Text color={'font.2'} w={{ base: '80%', md: '560px' }} align={'left'} mt={'24px'}>
              Brace yourselves! It&apos;s coming!
            </Text>
            <Text color={'font.2'} w={{ base: '80%', md: '560px' }} align={'left'} mt={'12px'}>
              AIBull&apos;s epic launch on zkSync is about to kickstart the thrilling Zealy presale
              event!
            </Text>
            <Text color={'font.2'} w={{ base: '80%', md: '560px' }} align={'left'} mt={'12px'}>
              5,000 AIBull in total, up to 2,000 will be available by whitelist.
            </Text>
            <Text color={'font.2'} w={{ base: '80%', md: '560px' }} align={'left'} mt={'12px'}>
              Whitelist Price: 0.003 ETH Max buy: 10/wallet
            </Text>
            <Text color={'font.2'} w={{ base: '80%', md: '560px' }} align={'left'} mt={'12px'}>
              General Price: 0.01 ETH Max buy: 30/wallet
            </Text>
            {/* Time */}
            {/* <Box
              mt={'28px'}
              pos={'relative'}
              px={'16px'}
              borderRadius={'16px'}
              zIndex={0}
              _before={{
                content: '""',
                zIndex: -2,
                pos: 'absolute',
                w: 'calc(100% + 1px)',
                h: 'calc(100% + 1px)',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '16px',
                bgImage: 'linear-gradient(90deg, rgb(251, 175, 39) 0%, rgb(246, 119, 17) 100%)',
              }}
              _after={{
                content: '""',
                zIndex: -2,
                pos: 'absolute',
                w: 'calc(100% - 1px)',
                h: 'calc(100% - 1px)',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '16px',
                bg: 'rgb(6, 8, 24)',
              }}
            >
              <Flex>
                <svg
                  viewBox="0 0 14 14"
                  width="14"
                  color="text"
                  xmlns="http://www.w3.org/2000/svg"
                  className="Svg-sc-1idgvhl-0 djTIXR"
                >
                  <defs>
                    <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
                      <stop stopColor="#FBAF29" offset="0%"></stop>
                      <stop stopColor="#F67712" offset="100%"></stop>
                    </linearGradient>
                  </defs>
                  <g id="g" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                      d="M7,0 C10.865993,0 14,3.13400664 14,7 C14,10.865993 10.865993,14 7,14 C3.13400664,14 0,10.865993 0,7 C0,3.13400664 3.13400664,0 7,0 Z M7.8,2.2 L6.2,2.2 L6.2,7.33137093 L8.83431454,9.96568527 L9.96568527,8.83431473 L7.8,6.66862946 L7.8,2.2 Z"
                      id="path"
                      fill="url(#linearGradient-1)"
                      fillRule="nonzero"
                    ></path>
                  </g>
                </svg>
                <Text
                  bg={'linear-gradient(90deg, rgb(251, 175, 39) 0%, rgb(246, 119, 17) 100%) text'}
                  color={'transparent'}
                  ml={'6px'}
                >
                  2024.03.20 20:00(UTC+8) - 2024.03.21 20:00(UTC+8)
                </Text>
              </Flex>
            </Box> */}
            <Flex
              maxW={'90vw'}
              w={{ base: '80%', md: '560px' }}
              mt={'44px'}
              justifyContent={'space-between'}
            >
              <Text color={'font.2'} fontSize={'14px'}>
                Total
              </Text>
              <Text color={'font.2'} fontSize={'14px'}>
                5,000
              </Text>
            </Flex>
            <Box maxW={'90vw'} w={{ base: '80%', md: '560px' }} mt={'12px'}>
              <Box
                pos={'relative'}
                bg={'blue.2'}
                boxShadow={'rgba(16, 64, 54, 0.21) 0px 1px 3px 0px inset'}
                w={'100%'}
                overflow={'hidden'}
                borderRadius={'32px'}
                h={'10px'}
              >
                <Box
                  pos={'absolute'}
                  top={0}
                  left={0}
                  h={'100%'}
                  bgImage={'linear-gradient(270deg, rgb(49, 214, 249) 0%, rgb(156, 81, 255) 100%)'}
                  transition={'width 200ms ease 0s'}
                  w={'1%'}
                  borderRadius={'32px'}
                ></Box>
              </Box>
            </Box>
            <Flex pos={'relative'}>
              <Input
                placeholder="Basic usage"
                defaultValue={0}
                w={'560px'}
                maxW={'90vw'}
                type="number"
                min={0}
                max={30}
                h={'56px'}
                border={'2px solid rgb(60, 74, 113)'}
                bg={'rgb(30, 39, 64)'}
                mt={'22px'}
                fontSize={'18px'}
                value={mintValue}
                onChange={(e) => {
                  console.log(e.target.value, !!e.target.value, Number(e.target.value))
                  if (!e.target.value) {
                    setMintValue('0')
                  } else {
                    setMintValue(Math.min(Number(e.target.value), userMax).toString())
                  }
                }}
              />
              <Button
                h="30px"
                onClick={() => {
                  setMintValue('30')
                }}
                pos={'absolute'}
                color={'rgb(14, 19, 33)'}
                bg={'linear-gradient(270deg, rgb(51, 212, 250) 0%, rgb(23, 243, 221) 100%)'}
                borderRadius={'4px'}
                overflow={'hidden'}
                w={'60px'}
                zIndex={3}
                right={0}
                bottom={0}
                transform={'translate(-25%, -50%)'}
                _hover={{
                  bg: 'linear-gradient(270deg, rgb(51, 212, 250) 0%, rgb(23, 243, 221) 100%)',
                }}
              >
                Max
              </Button>
            </Flex>

            <Button
              mt={'42px'}
              color={'rgb(14, 19, 33)'}
              bg={'linear-gradient(270deg, rgb(51, 212, 250) 0%, rgb(23, 243, 221) 100%)'}
              borderRadius={'12px'}
              overflow={'hidden'}
              w={'260px'}
              _hover={{
                opacity: 0.8,
              }}
              height={'52px'}
              fontSize={'18px'}
              // isDisabled
            >
              Mint
            </Button>
          </Flex>
        </Box>
      </Box>
      {/* 3 */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={'center'}
        justify={'center'}
        mt={'124px'}
      >
        <Box
          pos={'relative'}
          w={'360px'}
          _before={{
            content: '""',
            zIndex: -2,
            pos: 'absolute',
            w: 'calc(100% + 2px)',
            h: 'calc(100% + 2px)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '32px',
            bgImage: 'linear-gradient(270deg, #53f2d4 0%, rgb(85, 161, 255) 50%, #53f2d4 100%)',
          }}
          _after={{
            content: '""',
            zIndex: -2,
            pos: 'absolute',
            w: 'calc(100% - 2px)',
            h: 'calc(100% - 2px)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '32px',
            bg: 'rgb(6, 8, 24)',
          }}
        >
          <Image
            src={PhotoImage}
            alt="photo"
            pos={'relative'}
            maxW={'360px'}
            maxH={'360px'}
            borderRadius={'32px'}
          />
        </Box>
        <Flex
          maxW={'500px'}
          direction={'column'}
          ml={{ base: '0px', md: '100px' }}
          mt={{ base: '50px', md: '0px' }}
          px={{ base: '10px', md: '0px' }}
        >
          <Text fontSize={'3xl'}>Welcome to AIBull</Text>
          <Text color={'font.2'} mt={'8px'}>
            In the year 2100, the pinnacle of AI technology has transformed the universe into a
            realm of dazzling AI achievements. Among the predominant trends of AI, a new breed of
            combatants known as {`"AIBull"`} has emerged.
          </Text>
          <Text color={'font.2'} mt={'8px'}>
            Against this backdrop, the stage is set for a spectacular event: the AIBull Fighting
            Championship. Elite AIBulls from across the galaxies converge to engage in breathtaking
            battles, where each encounter is a test of both intellect and might. Every participant
            in this grand tournament aspires to claim the title of the {`universe's`} strongest AI
            Bull.
          </Text>
          <Link href="https://ai-bull.gitbook.io/aibull-1" isExternal>
            <Button
              rightIcon={<ExternalLinkIcon />}
              variant="outline"
              borderColor={'rgb(23, 243, 221)'}
              color={'rgb(23, 243, 221)'}
              bgColor={'transparent'}
              _hover={{
                bgColor: 'transparent',
              }}
              fontSize={'12px'}
              fontWeight={600}
              w={'120px'}
              h={'40px'}
              mt={'16px'}
              borderRadius={'8px'}
            >
              View Docs
            </Button>
          </Link>
        </Flex>
      </Flex>
      {/* 4 */}
      <Flex
        direction={'column'}
        align={'center'}
        justify={'center'}
        maxW={'1440px'}
        mx={'auto'}
        mt={{ base: '80px', md: '200px' }}
        id="earn"
      >
        <Image src={IconTop} alt="icon" />
        <Text fontSize={{ base: '3xl', md: '5xl' }}>Earn With AIBull</Text>
        <SimpleGrid columns={[1, null, 3]} spacing="40px" mt={'100px'}>
          <Card
            bg={'#0f0d14'}
            pos={'relative'}
            color={'#fff'}
            h={'300px'}
            border={'1px solid #7774ff'}
            _hover={{
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
            }}
          >
            <Image src={One} alt="1" pos={'absolute'} top={'-60px'} left={0} zIndex={-1} />
            <CardHeader bg={'#7774ff'} borderRadius={'8px 8px 0 0'}>
              <Heading size="md">AIBull Arena</Heading>
            </CardHeader>
            <CardBody>
              <Image src={Icon1} alt="1" />
              <Text fontSize={'14px'} mt={'16px'}>
                The AIBull Arena is a strategic and challenging battleground where players must
                select and train their AIBulls, devise effective tactics, and refine their combat
                skills through continuous practice.
              </Text>
            </CardBody>
          </Card>
          <Card
            bg={'#0f0d14'}
            pos={'relative'}
            color={'#fff'}
            h={'300px'}
            border={'1px solid #7774ff'}
            _hover={{
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
            }}
          >
            <Image src={Two} alt="1" pos={'absolute'} top={'-60px'} left={0} zIndex={-1} />
            <CardHeader bg={'#7774ff'} borderRadius={'8px 8px 0 0'}>
              <Heading size="md">AIBull Pasture</Heading>
            </CardHeader>
            <CardBody>
              <Image src={Icon2} alt="1" />
              <Text fontSize={'14px'} mt={'16px'}>
                AIBull Pasture is a unique mining pool set up for all AIBull NFTs. Players can
                assemble their AIBull NFTs into teams and stake them in the AIBull Pasture to earn
                AIB (the {`platform's`} governance token).
              </Text>
            </CardBody>
          </Card>
          <Card
            bg={'#0f0d14'}
            pos={'relative'}
            color={'#fff'}
            h={'300px'}
            border={'1px solid #7774ff'}
            _hover={{
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
            }}
          >
            <Image src={Three} alt="1" pos={'absolute'} top={'-60px'} left={0} zIndex={-1} />
            <CardHeader bg={'#7774ff'} borderRadius={'8px 8px 0 0'}>
              <Heading size="md">Prairie & Wonderland</Heading>
            </CardHeader>
            <CardBody>
              <Image src={Icon3} alt="1" />
              <Text fontSize={'14px'} mt={'16px'}>
                Players can engage with the AIBull breeding system, enhancing the strength of their
                bullfighting teams through meticulous cultivation. Limited edition AIBull NFTs, or
                other valuable rewards.
              </Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Flex>
      <Box pos={'relative'} w={'100%'} h={'700px'} mt={'140px'}>
        <Image src="/video.gif" alt="" pos={'absolute'} />
        <Flex
          pos={'absolute'}
          top={0}
          left={0}
          w={'100%'}
          height={'100%'}
          align={'center'}
          justify={'center'}
        >
          <Map />
        </Flex>
      </Box>
      {/* 5 */}
      <Flex
        direction={'column'}
        align={'center'}
        justify={'center'}
        maxW={'1440px'}
        mx={'auto'}
        mt={'200px'}
        pt={{ base: '50px', md: '350px' }}
      >
        <Image src={Icon4} alt="icon" />
        <Text fontSize={{ base: '2xl', md: '5xl' }}>Communities & Partners</Text>
        <SimpleGrid columns={[2, null, 6]} spacing="20px" mt={'100px'}>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'rgb(27, 29, 39)'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={BinanceIcon} alt="icon" />
          </Flex>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'#fff'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={LogoGalxe} alt="icon" w={'190px'} />
          </Flex>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'rgb(27, 29, 39)'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={LogoOkx} alt="icon" w={'100px'} />
          </Flex>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'#fff'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={LogoZks} alt="icon" w={'190px'} />
          </Flex>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'rgb(27, 29, 39)'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={LogoTp} alt="icon" w={'190px'} />
          </Flex>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'rgb(27, 29, 39)'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={LogoMM} alt="icon" w={'190px'} />
          </Flex>
          <Flex
            align={'center'}
            justify={'center'}
            h={'82px'}
            bg={'rgb(27, 29, 39)'}
            opacity={0.6}
            transition={'all 0.2s ease-out 0s'}
            _hover={{
              border: '1px solid #7774ff',
              boxShadow: '0 2px 69px rgba(89,86,229,.6)',
              opacity: 1,
              transform: 'scale(1.1)',
              borderRadius: '8px',
            }}
          >
            <Image src={LogoPs} alt="icon" w={'190px'} />
          </Flex>
        </SimpleGrid>
      </Flex>
      <Footer />
    </>
  )
}

export default Home
