import { Chains, Network } from './constants'
import CrazyHeroBlindBoxPreAbi from '@/contracts/abi/CrazyHeroBlindBoxPre-abi.json'
import CRTAbi from '@/contracts/abi/CRT-abi.json'
import CrazyHeroNFTBaseAbi from '@/contracts/abi/CrazyHeroNFTBase.json'
import CrazyHeroNFTFactoryAbi from '@/contracts/abi/CrazyHeroNFTFactory-abi.json'
import CrazyMarketAbi from '@/contracts/abi/CrazyMarket.json'
import CrazyHeroNFTGAbi from '@/contracts/abi/CrazyHeroNFTG1.json'

export const contractCRTConfig = {
  address: Chains[Network]['CRT'],
  abi: CRTAbi as any,
}
export const contractFCConfig = {
  address: Chains[Network]['FC'],
  abi: CRTAbi as any,
}

export const contractBlindBoxConfig = {
  address: Chains[Network]['CrazyHeroBlindBoxPre'],
  abi: CrazyHeroBlindBoxPreAbi as any,
}

export const contractNFTFactoryConfig = {
  address: Chains[Network]['CrazyHeroNFTFactory'],
  abi: CrazyHeroNFTFactoryAbi as any,
}

export const contractNFTBaseConfig = {
  address: Chains[Network]['CrazyHeroNFT'],
  abi: CrazyHeroNFTBaseAbi as any,
}

export const contractCrazyMarketConfig = {
  address: Chains[Network]['CrazyMarket'],
  abi: CrazyMarketAbi as any,
}

export const contractCrazyHeroNFTGAbiConfig = {
  address: Chains[Network]['GrazyHeroNFTG1'],
  abi: CrazyHeroNFTGAbi as any,
}
