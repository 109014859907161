import { ReactNode } from 'react'
import { Stack, Container, Box, Text, Heading, SimpleGrid } from '@chakra-ui/react'

export default function StatsGridWithImage() {
  return (
    <Box position={'relative'} w={'100%'} id="roadmap" pt={{ base: '50px', md: '50px' }}>
      <Container maxW={'7xl'} zIndex={10} position={'relative'}>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Stack flex={1} color={'gray.400'} justify={{ lg: 'center' }}>
            <Box mb={{ base: 8, md: 20 }}>
              <Heading
                color={'white'}
                mb={10}
                fontSize={{ base: '3xl', md: '5xl' }}
                textAlign={'center'}
              >
                Roadmap
              </Heading>
              <Text fontSize={'xl'} color={'gray.400'} align={'center'}>
                We are excited to unveil the roadmap for AIBull development. We are committed to
                continuously improving and updating the project to enhance the user experience. Stay
                tuned for exciting updates that will take your experience with AIBull to the next
                level!
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text fontFamily={'heading'} fontSize={'3xl'} color={'white'} mb={3}>
                    {stat.title}
                  </Text>
                  <Text fontSize={'xl'} color={'gray.400'}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as={'span'} fontWeight={700} color={'white'}>
    {children}
  </Text>
)

const stats = [
  {
    title: 'Phase 1',
    content: (
      <>
        <StatsText>Whitepaper Creation</StatsText>
        <br />
        <StatsText>Game design</StatsText>
        <br />
        <StatsText>Economic modle design</StatsText>
        <br />
        <StatsText>Creation of Social Networks</StatsText>
        <br />
        <StatsText>Community Building</StatsText>
        <br />
      </>
    ),
  },
  {
    title: 'Phase 2',
    content: (
      <>
        <StatsText>AIBull Website</StatsText>
        <br />
        <StatsText>Marketing for Launch</StatsText>
        <br />
        <>AIBull NFT presale</>
        <br />
        <>Game development</>
        <br />
      </>
    ),
  },
  {
    title: 'Phase 3',
    content: (
      <>
        <>AIBull Arena alpha test</>
        <br />
        <>AIBull NFT farming</>
        <br />
        <>Boost Pool</>
        <br />
        <>AIBull Arena beta test</>
        <br />
      </>
    ),
  },
  {
    title: 'Phase 4',
    content: (
      <>
        <>AIBull Arena invites test</>
        <br />
        <>Community activities</>
        <br />
        <>Referral System</>
        <br />
        <>Game Mobile Version</>
        <br />
      </>
    ),
  },
]
