import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import XIcon from '@/assets/x.png'
import AIBullIcon from '@/assets/ai-bull-icon.png'

export const Logo = () => {
  return <Image src={AIBullIcon}></Image>
}

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function SmallWithLogoLeft() {
  return (
    <Box color={'#fff'} mt={'90px'}>
      <Container
        as={Stack}
        maxW={'100%'}
        py={10}
        px={10}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Logo />
        <Text fontSize={{ base: '12px', md: '16px' }}>
          © 2024 ZkSync AIBull World. All right reserved.
        </Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Twitter'} href={'https://twitter.com/AIBull_zkSync'}>
            <Image src={XIcon} alt="x" />
          </SocialButton>
          {/* <SocialButton label={'YouTube'} href={'#'}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              height="158.1"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              viewBox="-0.6 -0.1 173.4 158.1"
              width="173.4"
              zoomAndPan="magnify"
            >
              <g id="change1_1">
                <path
                  d="M7112.59,7188.7q69.57-33.18,92.75-43.95c44.12-20.23,53.4-23.72,59.36-23.87a9.93,9.93,0,0,1,6.23,2,8,8,0,0,1,2.25,4.8,27.57,27.57,0,0,1,.27,6.84c-2.39,27.65-12.72,94.74-18,125.59-2.26,13.09-6.63,17.46-10.87,17.9-9.27.87-16.3-6.7-25.17-13.1-14.05-10-21.86-16.3-35.51-26.19-15.77-11.35-5.57-17.61,3.44-27.8,2.39-2.62,43.06-43.36,43.86-47a3.73,3.73,0,0,0-.8-3.06c-.92-.87-2.25-.58-3.31-.29-1.46.29-23.71,16.59-67,48.75-6.36,4.8-12.06,7.13-17.23,7-5.69-.15-16.56-3.5-24.77-6.41-9.94-3.49-17.89-5.38-17.23-11.49C7101.2,7195.25,7105.17,7192,7112.59,7188.7Z"
                  fill="#fff"
                  fillRule="evenodd"
                  transform="translate(-7100.76 -7120.88)"
                />
              </g>
            </svg>
          </SocialButton> */}
        </Stack>
      </Container>
    </Box>
  )
}
