import type { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import axios from 'axios'

const host =
  process.env.NODE_ENV === 'development' || window.process?.env?.isProxy
    ? ''
    : window.process?.env?.host

/**
 * @param url
 * @param options
 */

export async function myRequest(
  url: string,
  options?: AxiosRequestConfig,
  errorHandleFun?: (res: any) => void
) {
  const defaultOptions = {
    header: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    timeout: 15000,
  }
  const newOptions: any = { ...options }
  newOptions.method = options?.method

  if (options?.method && ['post', 'delete', 'put'].includes(options.method.toLowerCase())) {
    newOptions.data = newOptions.params
    delete newOptions.params
  }

  axios.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers = defaultOptions.header
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // eslint-disable-next-line @typescript-eslint/no-shadow
  return new Promise((resolve) => {
    axios({
      url: url.indexOf('http') === -1 ? encodeURI(`${host}${url}`) : url,
      ...newOptions,
    })
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          if (res.data instanceof Blob) {
            resolve(res.data)
          }
          resolve(res.data?.data)
        } else {
          resolve(null)
          if (errorHandleFun) {
            errorHandleFun(res)
          }
        }
      })
      .catch((_error) => {
        resolve(null)
        errorHandle(_error)
        if (errorHandleFun) {
          errorHandleFun(_error)
        }
      })
  })
}

function errorHandle(error: any) {
  const errorRes = error.response
  if (errorRes) {
    switch (errorRes.status) {
      case 400:
        errorRes.msg = '请求错误'
        break
      case 401:
        errorRes.msg = '未授权，请登录'
        break
      case 403:
        errorRes.msg = '拒绝访问'
        break
      case 404:
        errorRes.msg = `请求地址出错: ${errorRes.config.url}`
        break
      case 408:
        errorRes.msg = '请求超时'
        break
      case 500:
        errorRes.msg = '系统繁忙，请稍后再试。'
        break
      case 501:
        errorRes.msg = '服务未实现'
        break
      case 502:
        errorRes.msg = '网关错误'
        break
      case 503:
        errorRes.msg = '服务不可用'
        break
      case 504:
        errorRes.msg = '网关超时'
        break
      // case 505:
      //   errorRes.msg = 'HTTP版本不支持';
      //   break;
      default:
        break
    }
  }
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    console.error('>>>>>> Error >>>>>>')
    console.log(errorRes)
  }
}

export default async (
  url: string,
  methed: Method,
  params?: any,
  errorHandleFun?: (res: any) => void
): Promise<any> => {
  return await myRequest(
    url,
    {
      method: methed,
      params: params || {},
    },
    errorHandleFun
  )
}
