import React, { useEffect, useCallback } from 'react'
import '@rainbow-me/rainbowkit/styles.css'
import { useAccount, useBalance, useContractReads, usePublicClient } from 'wagmi'
import globalStore from '@/stores/global'
import {
  contractBlindBoxConfig,
  contractCRTConfig,
  contractFCConfig,
  contractNFTBaseConfig,
} from '@/contracts/contractConfig'
import { userHeroNftByTokenIdList } from '@/services/service'

export interface IUserContext {
  getUserNftList: () => Promise<any[]>
}

export const UserContext = React.createContext<IUserContext>({
  getUserNftList: () => Promise.resolve([]),
})

interface IProps {
  children: React.ReactNode
}

function RainbowkitProvider({ children }: IProps) {
  const { address } = useAccount()
  const publicClient = usePublicClient()

  useEffect(() => {
    globalStore.setState({
      publicClient: publicClient,
      userAddress: address,
    })
  }, [publicClient, address])

  const { data: balance } = useBalance({
    address: address,
  })

  const { data: userReads }: any = useContractReads({
    contracts: [
      {
        ...contractFCConfig,
        functionName: 'allowance',
        args: [address, contractBlindBoxConfig.address],
      },
      {
        ...contractCRTConfig,
        functionName: 'allowance',
        args: [address, contractBlindBoxConfig.address],
      },
      {
        ...contractBlindBoxConfig,
        functionName: 'accountBoxMap',
        args: [`${address}`],
      },
      {
        ...contractFCConfig,
        functionName: 'balanceOf',
        args: [`${address}`],
      },
      {
        ...contractCRTConfig,
        functionName: 'balanceOf',
        args: [`${address}`],
      },
      {
        ...contractNFTBaseConfig,
        functionName: 'getUserNftIdList',
        args: [`${address}`, 0, 10000],
      },
    ],
    watch: true,
    allowFailure: false,
  })

  useEffect(() => {
    console.log('userReads-', userReads, balance)
    if (userReads?.length > 0 && balance) {
      const userData = {
        allowanceFC: userReads[0],
        allowanceCFX: BigInt(0),
        allowanceCRT: userReads[1],
        accountBoxMap: {
          count: userReads[2]?.[1],
          boxIndex: userReads[2]?.[3],
        },
        userFCTotal: userReads[3],
        userCFXTotal: balance?.value || BigInt(0),
        userCRTTotal: userReads[4],
        userNfts: {
          nftIdList: userReads[5][0],
          size: userReads[5][1],
        },
      }
      globalStore.setState({
        userData: userData,
      })
    }
  }, [userReads, balance])

  const getUserNftList = useCallback(async () => {
    if (userReads?.[5]?.[0]) {
      const list = await userHeroNftByTokenIdList(userReads[5][0], [])
      return list
    }
    return []
  }, [userReads?.[5]?.[0]])

  const contextValue = {
    getUserNftList,
  }
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export default RainbowkitProvider
